<template>
	<div class="img">
		<!-- <img :src="factoryLink" ref='img'> -->
		<!-- <div >
			<div class="viewer2" ref='viewer2'>
				<p><img src="../assets/img/yulanfanhui.svg" alt=""></p>
			</div>
			
		</div> -->
		<div style="width: 340px; margin: 0 auto; padding-top: 100px;">
			<p style="margin-bottom: 30px;"><img src="../assets/img/360du.svg" alt="" style="display: block; margin: 0 auto;width: 50%;"></p>
			<el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/" multiple :auto-upload="false" :file-list="fileList"
			 :on-change="selectIMG">
				<i class="icon"></i>
				<div class="el-upload__text" style="margin-top: 20px;">将您的图片，选择或拖入放到这里！</div>
				<!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
			</el-upload>
		</div>
		<div id="viewer" class="viewer" ref='viewer'>
		</div>



	</div>


</template>
<script>
	import {
		Viewer
	} from 'photo-sphere-viewer';
	import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
	export default {
		name: "",
		data() {
			return {
				factoryLink: require('../assets/img/yulanfanhui.svg'),
				fileList:[]
				// require('C:/Users/Administrator/Desktop/quanjingtu.png') 

			};
		},
		props: {},
		methods: {
			selectIMG(files) {
				let _this=this;
				let file = files.raw;
				let url = window.URL || window.webkitURL;
				let src = url.createObjectURL(file);
				var viewer = new Viewer({
					container: 'viewer',
					panorama: src,
					autorotateDelay:100,
					navbar: [
						'autorotate',
						'zoom',
						'download',
						'caption',
						'fullscreen',
						{
						id: 'my-button',
						content: '<img src="'+this.factoryLink+'" alt="" style="width:120%;opacity: 0.8;">',
						title: '返回',
						className: 'custom-button',
						onClick: () => {
							this.fileList=[];
							this.$refs.viewer.style.visibility = 'hidden';
							viewer.destroy();
						}
					}, 
					],
					
				});
				this.$refs.viewer.style.visibility = 'visible';
				
			}
		},
		mounted() {

		},
	}
</script>
<style scoped>
	.viewer {
		width: 100vw;
		height: 100vh;
		visibility: hidden;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 999999999999;
	}

	.img {
		height: 70.4vh;
		width: 100%;
		background-image: url(../assets/img/NewLoginBack.jpg) ;
		background-repeat:no-repeat;
		background-color: #f7f8f8;
		background-position:bottom left 
	}

	>>>.el-upload-dragger {
		border-color: #c43e1c;
	}

	.icon {
		background: url(../assets/img/xuanze.svg) no-repeat bottom center;
		width: 50px;
		height: 100px;
		display: block;
		margin: 0 auto;
	}

	.viewer2 {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 999999999999999999999999;
		visibility: hidden;
	}
</style>
